<template>
  <v-card class="mx-auto change-card" :loading="loading" :outlined="$vuetify.breakpoint.smAndUp" :flat="$vuetify.breakpoint.xsOnly">
    <v-img class="mx-auto logo" :src="logo"></v-img>
    <div class="change-title">
       Cambiar contraseña
    </div>
    <div class="step-container">
      <div class="step-title">
        Crear una contraseña segura
      </div>
      <div class="step-description">
        Crea una contraseña nueva y segura que no uses en otros sitios web
      </div>
    </div>
    <v-form ref="form">
      <v-card-text class="change-card-text">
        <v-row justify="center" no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="user.password"
              label="Crear contraseña"
              :rules="[isRequired]"
              :type="passwordIsVisible ? 'text' : 'password'"
              outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="user.repeatedPassword"
              label="Confirmación"
              :rules="[isRequired, passwordsMatch]"
              :type="passwordIsVisible ? 'text' : 'password'"
              outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="text-left show-password-column">
            <v-checkbox label="Mostrar contraseña" @change="showPassword">
            </v-checkbox>
          </v-col>
          <v-col cols="12" class="text-left actions-column">
            <v-row justify="center" no-gutters>
              <v-col>
                <v-btn class="text-none login-button" text color="primary" @click="goToRouteByName('login')">
                  Acceder a tu cuenta
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn class="text-none" depressed color="primary" :loading="loading" @click="change">
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import logo from '@/assets/logo.jpg'
import { isRequired } from '@/shared/validations'

export default {
  mixins: [navigationMixin],
  data () {
    return {
      logo,
      user: {
        password: '',
        repeatedPassword: ''
      },
      passwordServerMessage: null,
      passwordIsVisible: false
    }
  },
  computed: {
    ...mapState('shared', ['error', 'loading']),
    passwordsMatch () {
      return () => this.user.password === this.user.repeatedPassword || 'Las contraseñas no coinciden.'
    }
  },
  methods: {
    isRequired,
    ...mapActions('auth', ['changePassword']),
    ...mapActions('notification', ['showNotification']),
    showPassword (isVisible) {
      this.passwordIsVisible = isVisible
    },
    async change () {
      if (!this.$refs.form.validate()) {
        return
      }

      const user = {
        email: this.$route.query.email,
        token: this.$route.query.token,
        password: this.user.password
      }

      await this.changePassword(user)
      if (this.error) {
        this.showNotification({ message: this.error.message })
        return
      }

      this.showNotification({ message: 'Contraseña modificada exitosamente' })
      this.goToRouteByName('login')
    }
  }
}
</script>
<style scoped>
.change-card {
  width: 28rem;
  min-height: 31.375rem;
}
.logo {
  margin-top: 2.25rem;
  margin-bottom: 1rem;
  width: 5.625rem;
}
.change-title {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
}
.step-container{
  text-align: left;
  padding-left: 3rem;
  padding-right: 3rem;
}
.step-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}
.step-description {
  font-size: 0.875rem;
  margin-bottom: 0.2rem;
}
.change-card-text {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
}
.show-password-column {
  margin-top: -1rem;
}
.actions-column {
  margin-top: 0.5rem;
}
.login-button {
  margin-left: -1rem;
}
/* 599px */
@media only screen and (max-width: 37.438rem) {
  .logo {
    margin-top: 1.5rem;
  }
  .step-container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .change-card-text {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
</style>
